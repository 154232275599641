<template>
  <div class="cards" v-if="isLoaded">

    <div class="card" v-if="!wishlist.investment_account">
      <div class="card-image">
        <img src="@/assets/images/invest/ops_graph.svg">
      </div>
      <div class="card-desc">Gain access to unlimited trading opportunities including trading in stocks, bonds, currencies and a variety of financial derivatives. Apply now and be among the first clients to enjoy the world of investing</div>
      <div class="card-btns">
        <button class="default-button" type="button" @click.prevent="makeOrder">Apply now</button>
      </div>
    </div>
    <div class="success-block" v-else>
      <div class="success-block-image">
        <img src="@/assets/images/cards/thanks.svg">
      </div>
      <div class="success-block-title">
        <span class="yellow-colored">Thank you</span><br/>
        for your interest!
      </div>
      <div class="success-block-desc">Stay tuned for our latest addition of a fully fledged trading account.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  computed: {
    isLoaded () {
      return this.$asyncComputed.wishlist.state === 'success'
    }
  },
  asyncComputed: {
    wishlist: {
      get () {
        return this.$sdk.wishlist.list()
            .then(response => response.data)
      }
    }
  },
  methods: {
    async makeOrder (event) {
      await this.$sdk.wishlist.update({
        investment_account: true
      })

      this.$asyncComputed.wishlist.update()
    }
  },
}
</script>

<style lang="less" scoped>
.cards {
  justify-content: center;
  align-items: center;

  width: 100%;
  display: flex;
}

.card {
  max-width: 674px;
  margin: 0 auto;
  padding: 80px;
  box-sizing: content-box;

  &-image {
    text-align: center;

    img {
      max-width: 100%;
    }
  }
  &-desc {
    margin: 23px 0 30px;

    opacity: 0.8;
    color: #FFFFFF;

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }
  &-btns {
    text-align: center;
  }
}

.success-block {
  text-align: center;

  &-title {
    color: #FFFFFF;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 72px;
    text-align: center;

    margin: 23px 0 10px;
  }
  &-desc {
    opacity: 0.7;
    color: #FFFFFF;

    font-size: 17px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }
}
</style>